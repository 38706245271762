<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-1">
            <div>
                <b-button
                    :to="{ name:'create-post'}"
                    class="mr-1"
                    variant="relief-primary"
                >
                    {{ $t('new') }}
                </b-button>
                <b-button
                    class="mr-1"
                    variant="info"
                    @click="visible = !visible"
                >
                    {{ $t('search') }}
                </b-button>
                <b-button
                    :to="{ name: 'posts-deleted'}"
                    variant="warning"
                >
                    {{ $t('deleted') }}
                </b-button>
            </div>
            <div>
                <span>تعداد کل: </span>
                {{ pagination.total }}
            </div>
        </div>
        <custom-filter
            :filters="filters"
            :visible="visible"
            @onSubmit="filters => filter(filters)"
        >
            <template #form="{ filters }">
                <b-row>
                    <b-col md="6">
                        <custom-input
                            v-model="filters.title"
                            :name="$t('title')"
                            vid="title"
                        />
                    </b-col>
                    <b-col md="3">
                        <custom-input
                            v-model="filters.imdb"
                            :name="$t('imdb')"
                            vid="imdb"
                        />
                    </b-col>
                    <b-col md="3">
                        <custom-select
                            v-model="filters.categories.id"
                            :name="$t('category._')"
                            :options="selectableCategories"
                            text-field="label"
                            vid="category_id"
                        />
                    </b-col>
                </b-row>
            </template>
        </custom-filter>
        <b-card no-body>
            <b-table :busy="status === 'LOADING'" :fields="fields" :items="Object.values(posts)" hover responsive>
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"/>
                    </div>
                </template>
                <template #cell(status)="data">
                    <b-badge :variant="statusColor(data.item.status)">
                        {{ statusText(data.item.status) }}
                    </b-badge>
                </template>

                <template #cell(action)="data">
                    <b-dropdown
                        no-caret
                        variant="link"
                    >
                        <template #button-content>
                            <feather-icon icon="MoreHorizontalIcon"/>
                        </template>
                        <b-dropdown-item :to="{name: 'edit-post', params:{id: data.item.id}}">
                            <feather-icon icon="Edit2Icon"/>
                            {{ $t('edit') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="XIcon"/>
                            {{ $t('delete') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-pagination
                v-if="pagination.last_page > 1"
                v-model="pagination.current_page"
                :per-page="pagination.per_page"
                :total-rows="pagination.total"
                align="center"
                class="mt-2"
                @change="paginationCategory"
            />
        </b-card>
    </div>
</template>

<script>
import fields from "./partials/fields";
import { mapActions, mapGetters } from "vuex";
import CategoryStatus from "@/constans/CategoryStatus";
import PostStatus from "@/constans/PostStatus";

export default {
    name: "Posts",

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchPosts()
        })
    },

    data() {
        return {
            fields,
            visible: false,
            filters: {
                title: '',
                categories: {
                    id: ''
                },
                genres: {
                    id: 1
                },
                imdb: ''
            }
        }
    },

    computed: {
        ...mapGetters('post', [
            'posts', 'status', 'pagination'
        ]),
        ...mapGetters('filter', [
            'filterFields'
        ]),
        ...mapGetters('category', [
            'selectableCategories'
        ])
    },

    mounted() {
        this.fetchCategories()
    },

    methods: {
        ...mapActions({
            fetchPosts: 'post/fetchNotDeleted',
            deletePost: 'post/delete',
            fetchCategories: 'category/fetch',
        }),
        filter(filters) {
            this.fetchPosts({
                ...filters,
                page: 1
            })
        },
        paginationCategory(page) {
            this.fetchPosts({
                page,
                ...this.filterFields
            })
        },
        confirmDelete(id) {
            this.$swal({
                title: this.$t('Are you sure?'),
                // text: this.$t("You won't be able to revert this!"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes, delete it!'),
                cancelButtonText: this.$t('No'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.deletePost(id).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.$t(`Deleted!`),
                            text: this.$t('Your file has been deleted.'),
                            confirmButtonText: this.$t('Ok!'),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    })
                }
            })
        },
        statusColor(value) {
            return value === PostStatus.DRAFT ? 'warning' : 'success'
        },
        statusText(value) {
            if (value === PostStatus.DRAFT) return this.$t('draft')
            if (value === PostStatus.PUBLISHED) return this.$t('published')
        },

    }
}
</script>
